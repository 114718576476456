define("ember-cli-qrcode/components/qr-code", ["exports", "@glimmer/component", "ember-cli-qrcode/system/qr-code"], function (_exports, _component, _qrCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    width={{this.size}}
    height={{this.size}}
    viewBox={{this.viewBox}}
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ...attributes
  >
    <rect fill={{this.lightColor}} width="100%" height="100%"></rect>
    <rect
      fill={{this.darkColor}}
      width="1"
      height="1"
      id="template-{{this.elementId}}"
    ></rect>
  
    {{#each this.rows as |cols y|}}
      {{#each cols as |visible x|}}
        {{#if visible}}
          <use x={{x}} y={{y}} xlink:href="#template-{{this.elementId}}"></use>
        {{/if}}
      {{/each}}
    {{/each}}
  </svg>
  */
  {
    "id": "efEbJUGH",
    "block": "[[[11,\"svg\"],[16,\"width\",[30,0,[\"size\"]]],[16,\"height\",[30,0,[\"size\"]]],[16,\"viewBox\",[30,0,[\"viewBox\"]]],[24,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[17,1],[12],[1,\"\\n  \"],[10,\"rect\"],[15,\"fill\",[30,0,[\"lightColor\"]]],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[15,\"fill\",[30,0,[\"darkColor\"]]],[14,\"width\",\"1\"],[14,\"height\",\"1\"],[15,1,[29,[\"template-\",[30,0,[\"elementId\"]]]]],[12],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"rows\"]]],null]],null],null,[[[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[41,[30,4],[[[1,\"        \"],[10,\"use\"],[15,\"x\",[30,5]],[15,\"y\",[30,3]],[15,\"xlink:href\",[29,[\"#template-\",[30,0,[\"elementId\"]]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"]],[]],null]],[4,5]],null]],[2,3]],null],[13]],[\"&attrs\",\"cols\",\"y\",\"visible\",\"x\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "ember-cli-qrcode/components/qr-code.hbs",
    "isStrictMode": false
  });
  let QRCode = (_dec = Ember._tracked, (_class = class QRCode extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "text", _descriptor, this);
      this.size = this.args.size || Ember.String.htmlSafe("100%");
      this.darkColor = this.args.darkColor || "#000000";
      this.lightColor = this.args.lightColor || "#FFFFFF";
    }
    get code() {
      if (!this.args.text) {
        throw new Error("Must pass @text argument");
      }
      return (0, _qrCode.default)(this.args.text);
    }
    get viewBox() {
      const nCount = this.code.getModuleCount();
      return Ember.String.htmlSafe(`0 0 ${nCount} ${nCount}`);
    }
    get rows() {
      const {
        code
      } = this;
      const nCount = code.getModuleCount();
      return [...Array(nCount)].map((_, row) => {
        return [...Array(nCount)].map((_, col) => {
          return code.isDark(row, col);
        });
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "text", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = QRCode;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, QRCode);
});